import Cookies from "js-cookie";
import styles from "./CookiesBanner.module.scss";

interface CookiesBannerProps {
  handleDrawerChange: () => void;
}

export const CookiesBanner: React.FC<CookiesBannerProps> = ({
  handleDrawerChange,
}) => {
  const handleAccept = () => {
    Cookies.set("employerPortal_cookiesAccepted", "true", {
      expires: 10,
      secure: true,
    });
    handleDrawerChange();
  };

  const handleReject = () => {
    Cookies.set("employerPortal_cookiesAccepted", "false", {
      expires: 10,
      secure: true,
    });
    handleDrawerChange();
  };

  return (
    <div className={styles.BannerContainer}>
      <div className={styles.TextWrapper}>
        <p className={styles.BannerTitle}>Cookies on our website</p>
        <p className={styles.BannerText}>
          We use cookies to improve and customise your browsing experience. See
          our{" "}
          <a href="https://www.unum.co.uk/how-we-use-cookies" target="_blank">
            How we use cookies page
          </a>{" "}
          for more information. By clicking 'Accept all', you agree to the
          storing of cookies on your device to enhance site navigation, analyse
          site usage and help us in our marketing.
        </p>
      </div>

      <div className={styles.ButtonWrapper}>
        <button className={styles.ButtonAccept} onClick={handleAccept}>
          Accept all
        </button>
        <button className={styles.ButtonReject} onClick={handleReject}>
          Reject all
        </button>
      </div>
    </div>
  );
};

export default CookiesBanner;
