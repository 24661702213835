import { products } from "../types/enums/Products/Products";
import { lazy } from "react";

const GIP_ClaimSummary = lazy(
  () => import("../pages/GIP/ClaimSummary/ClaimSummary")
);
const GIP_EligibilityQuestions = lazy(
  () => import("../pages/GIP/EligibilityQuestions/EligibilityQuestions")
);
const GIP_EmployeeDetails = lazy(
  () => import("../pages/GIP/EmployeeDetails/EmployeeDetails")
);
const GIP_PaymentDetails = lazy(
  () => import("../pages/GIP/PaymentDetails/PaymentDetails")
);
const GIPSubmissionConfirmation = lazy(
  () => import("../pages/GIP/SubmissionConfirmation/SubmissionConfirmation")
);
const GIP_WorkDetails = lazy(
  () => import("../pages/GIP/WorkDetails/WorkDetails")
);

const GL_ClaimSummary = lazy(
  () => import("../pages/GL/ClaimSummary/ClaimSummary")
);
const GL_EligibilityQuestions = lazy(
  () => import("../pages/GL/EligibilityQuestions/EligibilityQuestions")
);
const GL_PaymentDetails = lazy(
  () => import("../pages/GL/PaymentDetails/PaymentDetails")
);
const GLSubmissionConfirmation = lazy(
  () => import("../pages/GL/SubmissionConfirmation/SubmissionConfirmation")
);

const REHAB_ClaimSummary = lazy(
  () => import("../pages/REHAB/ClaimSummary/ClaimSummary")
);
const REHAB_EligibilityQuestions = lazy(
  () => import("../pages/REHAB/EligibilityQuestions/EligibilityQuestions")
);
const REHAB_EmployeeDetails = lazy(
  () => import("../pages/REHAB/EmployeeDetails/EmployeeDetails")
);
const REHAB_SubmissionConfirmation = lazy(
  () => import("../pages/REHAB/SubmissionConfirmation/SubmissionConfirmation")
);

const CIC_ClaimSummary = lazy(
  () => import("../pages/CIC/ClaimSummary/ClaimSummary")
);
const CIC_EmployeeDetails = lazy(
  () => import("../pages/CIC/EmployeeDetails/EmployeeDetails")
);
const CIC_SubmissionConfirmation = lazy(
  () => import("../pages/CIC/SubmissionConfirmation/SubmissionConfirmation")
);

const pageNotFound = () => {
  return (
    <div
      style={{
        padding: "30px",
      }}
    >
      <h5>
        {" "}
        Please go back to Policy selection and choose the valide product{" "}
      </h5>
    </div>
  );
};

export const employeeDetailsReouting = (product: string) => {
  if (product === products.GIP.backend) {
    return <GIP_EmployeeDetails />;
  } else if (product === products.REHAB.backend) {
    return <REHAB_EmployeeDetails />;
  } else if (product === products.CIC.backend) {
    return <CIC_EmployeeDetails />;
  } else {
    return pageNotFound();
  }
};

export const eligibilityReouting = (product: string) => {
  if (product === products.GIP.backend) {
    return <GIP_EligibilityQuestions />;
  } else if (product === products.GL.backend) {
    return <GL_EligibilityQuestions />;
  } else if (product === products.REHAB.backend) {
    return <REHAB_EligibilityQuestions />;
  } else {
    return pageNotFound();
  }
};

export const WorkDetailsRouting = (product: string) => {
  if (product === products.GIP.backend) {
    return <GIP_WorkDetails />;
  } else {
    return pageNotFound();
  }
};

export const paymentRouting = (product: string) => {
  if (product === products.GIP.backend) {
    return <GIP_PaymentDetails />;
  } else if (product === products.GL.backend) {
    return <GL_PaymentDetails />;
  } else {
    return pageNotFound();
  }
};

export const claimSummaryRouting = (product: string) => {
  if (product === products.GIP.backend) {
    return <GIP_ClaimSummary />;
  } else if (product === products.GL.backend) {
    return <GL_ClaimSummary />;
  } else if (product === products.REHAB.backend) {
    return <REHAB_ClaimSummary />;
  } else if (product === products.CIC.backend) {
    return <CIC_ClaimSummary />;
  } else {
    return pageNotFound();
  }
};

export const claimSubmissionRouting = (product: string) => {
  if (product === products.GIP.backend) {
    return <GIPSubmissionConfirmation />;
  } else if (product === products.GL.backend) {
    return <GLSubmissionConfirmation />;
  } else if (product === products.REHAB.backend) {
    return <REHAB_SubmissionConfirmation />;
  } else if (product === products.CIC.backend) {
    return <CIC_SubmissionConfirmation />;
  } else {
    return pageNotFound();
  }
};
