import axios, { AxiosRequestConfig } from "axios";
import { logErrorToInsights } from "../ApplicationInsights";
import {
  checkAndLogErr,
  getTransId,
  compData,
  logNodeName,
} from "../utils/util";
import { TokenManager } from "@forgerock/javascript-sdk";

// Set up request interceptor
axios.interceptors.request.use(
  async (config) => {
    const token = await TokenManager.getTokens();
    if (token) {
      config.headers.Authorization = `Bearer ${token.accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Set up response interceptor
axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
     const { config } = error;
     const originalRequest = config;
  
     // Check if the error status indicates that the token has expired
      if (error?.response?.status === 401) {
        // Update the token
        const token = await TokenManager.getTokens();
  
        // Re-assign new token to original request and retry the request
        if (token) {
          originalRequest.headers.Authorization = `Bearer ${token.accessToken}`;
          return axios(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );


const apiCall = async (params: AxiosRequestConfig, data?: compData) => {
  try {
    const result = await axios(params);

    checkAndLogErr(result, params, data);

    return result.data;
  } catch (error: any) {
    const currentDate = new Date();

    logErrorToInsights(
      `${getTransId(params)},${currentDate}
      ),${logNodeName},${getTransId(params)},${data?.company},${
        data?.product
      },${params?.url},${params?.method},${error?.message},`
    );

    return error;
  }
};

export default apiCall;
