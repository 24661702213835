import UnumLogo from "../../../../assets/icons/unumLogo.svg";
import UnumLogoShort from "../../../../assets/icons/unumLogoShort.svg";
type navLogoProps = {
  isExpanded: boolean;
};

function NavLogo({ isExpanded }: navLogoProps) {
  return (
    <>
      {isExpanded ? (
        <img data-autoid="IMG_Long_Logo" src={UnumLogo} alt="Unum logo" />
      ) : (
        <img
          data-autoid="IMG_Short_Logo"
          src={UnumLogoShort}
          alt="Unum short logo"
        />
      )}
    </>
  );
}

export default NavLogo;
