import styled from "styled-components";

export const StyledBentoBox = styled.div<{
  gridTemplateColumns: string;
  gridTemplateRows: string;
}>`
  display: grid;
  gap: 24px;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  grid-template-rows: ${({ gridTemplateRows }) => gridTemplateRows};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-auto-flow: dense;
  }
`;

export const StyledChild = styled.div<{
  rowSpan: number;
  colSpan: number;
  mobileOrder: number;
}>`
  grid-row: span ${({ rowSpan }) => rowSpan};
  grid-column: span ${({ colSpan }) => colSpan};
  // border: 1px solid black; // Remove later
  display: flex;
  justifyself: start;

  @media (max-width: 768px) {
    order: ${({ mobileOrder }) => mobileOrder};
    grid-column: span 1 !important;
  }

  max-width: 768px;
`;
