import {
  userRolesType,
  forgerockRoleKey,
  forgerockCashplanRoleKey,
  userRoles,
  cashplanRoleScope,
} from "../../types/enums/User/Roles";
import { applications } from "../../types/applications";

export const roleCheck = (
  requiredRoles: userRolesType[],
  userRoles: userRolesType[],
  allRolesRequired = false
) => {
  let foundRoles = 0;
  requiredRoles.forEach((rRole) => {
    if (userRoles.includes(rRole)) {
      foundRoles = foundRoles + 1;
    }
  });

  if (allRolesRequired) {
    return foundRoles === requiredRoles.length;
  }
  return foundRoles > 0;
};

export const getRoleFromAttributes = (attList?: string[]): userRolesType => {
  if (!attList) {
    return undefined;
  }
  const roles = attList.filter((curr) => curr.includes(forgerockRoleKey));
  if (roles.length > 0) {
    const role = roles[0];
    switch (role) {
      case `${forgerockRoleKey}:${userRoles.normal}`:
        return userRoles.normal;
      case `${forgerockRoleKey}:${userRoles.admin}`:
        return userRoles.admin;
      case `${forgerockRoleKey}:${userRoles.super}`:
        return userRoles.super;
      case `${forgerockRoleKey}:${userRoles.enhanced}`:
        return userRoles.enhanced;
    }
  }
  return undefined;
};

//To do: Break out string to "cashplan Forgerock Role Key"
export const checkCashplanAccess = (attList?: string[]): boolean => {
  if (!attList || attList.length === 0) {
    return false;
  }
  for (let role of attList) {
    if (role === forgerockCashplanRoleKey + ":" + cashplanRoleScope) {
      return true;
    }
  }
  return false;
};

export const checkAppAccess = (
  usersAccess: applications[],
  requiredAccess: applications[]
) => {
  const foundRoles = requiredAccess.filter((role) =>
    usersAccess.includes(role)
  );

  return foundRoles.length === requiredAccess.length;
};
