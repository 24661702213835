import style from "./NavItem.module.scss";
import classNames from "classnames";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import * as analytics from "../../../../hooks/Analytics/ga4";

type navItemProps = {
  icon: ReactNode;
  expandedSec: ReactNode;
  isExpanded: boolean;
  linkTo: string;
  height?: string;
  analyticsName?: string;
  openinNewTab: boolean;
};
function NavItem({
  isExpanded,
  icon,
  expandedSec,
  linkTo,
  openinNewTab,
  height,
  analyticsName = "Not Known",
}: navItemProps) {
  const NIMain = classNames(style.NI_Main, {
    [style.expanded]: isExpanded,
  });
  return (
    <NavLink
      data-autoid={"LINK_" + linkTo.replace("/", "").replace(" ", "_")}
      target={openinNewTab ? "_blank" : ""}
      to={linkTo}
      className={({ isActive }) =>
        `${NIMain} ${isActive ? style.selected : ""}`
      }
      rel={openinNewTab ? "noopener noreferrer" : ""}
      data-testid="NavList-Item"
      style={{ height }}
      aria-label={linkTo.replace("/", "") + " link"}
      role="button"
      onClick={() => {
        analytics.sendPageEvent("EP Dashboard", "Link click", analyticsName);
      }}
    >
      <div className={style.NI_Icon} style={{ height: height }}>
        {icon}
      </div>
      <div className={style.NI_Extra} style={{ height: height }}>
        {expandedSec}
      </div>
    </NavLink>
  );
}

export default NavItem;
