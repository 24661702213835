import { useEffect, useState } from "react";
import { useAppSelector } from "./reduxhooks";
import { roleCheck } from "../utils/User/Roles";
import { applications } from "../types/applications";

const useGetUser = () => {
  const [appAccess, setAppAccess] = useState<applications[]>([]);
  const [hasEmployerAccess, setHasEmployerAccess] = useState(false);
  const [hasCashPlanAccess, setHasCashPlanAccess] = useState(false);

  const user = useAppSelector((state) => state.userSlice);

  useEffect(() => {
    let newAccess: applications[] = [];
    if (user?.hasCashplanAccess) {
      newAccess.push("cashPlan");
      setHasCashPlanAccess(true);
    }
    if (
      roleCheck([user.role], ["user", "enhanced", "super-user", "admin"], false)
    ) {
      newAccess.push("employerPortal");
      setHasEmployerAccess(true);
    }

    setAppAccess(newAccess);
  }, [user]);

  return { appAccess, hasEmployerAccess, hasCashPlanAccess, user };
};

export default useGetUser;
