import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontSize: 16,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 992,
      lg: 1200,
      xl: 1536,
    },
  },
  spacing: 10,
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#004470",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: "#F3B13B",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    error: {
      // light: will be calculated from palette.primary.main,
      main: "#A64441",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    info: {
      // light: will be calculated from palette.primary.main,
      main: "#0A6A36",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontFamily: "Ubuntu",
          borderRadius: "100px",
          letterSpacing: "-0.02em",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardError: {
          background: "#FBEAEA",
          color: "#541313",
          "& .MuiAlert-icon": {
            color: "#D52F2F",
          },
        },
        standardInfo: {
          background: "#E6F3FA",
          color: "#013654",
          "& .MuiAlert-icon": {
            color: "#0288D1",
          },
        },
      },
    },
  },
});
