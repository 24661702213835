import APP_URL_CONSTS from "../navigation/AppUrls";

export type page = {
  url: string;
  title: string;
  breadcrumbLabel: string;
  product?: string;
  showHelpButton: boolean;
};

const makeAClaim = "Make a claim";
const GroupIncomeJourney = "Make a claim (Group Income Protection)";
const GroupLifeJourney = "Make a claim (Group Life insurance)";
const RehabJourney = "Request Absence management support";
const CICJourney = "Make a claim (Group Critical Illness)";
const viewClaim = "View claim";

const havingHelpUrls = [
  { url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.WORK_DETAILS}` },
  { url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.ELIGIBILITY_DETAILS}` },
  { url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.ABSENCE_DETAILS}` },
  { url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.PAYMENT_DETAILS}` },
  { url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.VOC_REHB_SUPPORT}` },
  { url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.CLAIM_DETAILS}` },
  { url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.DECEASED_DETAILS}` },
  { url: `${APP_URL_CONSTS.CLAIMS}` },
  { url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.TRACKING}` },
  {
    url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.EMPLOYEE_DETAILS}`,
    excludedProducts: ["GIP", "GCI"],
  },
  { url: `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.OTHER_DETAILS}` },
];

const mainUrls = [
  APP_URL_CONSTS.CLAIMS,
  APP_URL_CONSTS.CLAIMS + "/",
  `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.POLICY_SELECTION}`,
  `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.TRACKING}`,
];

const pageData: page = {
  url: APP_URL_CONSTS.CLAIMS,
  title: "Claims",
  breadcrumbLabel: "Claims",
  showHelpButton: false,
};

const getMainURLsPageData = (url: string) => {
  if (url.includes(APP_URL_CONSTS.POLICY_SELECTION)) {
    return { ...pageData, title: "Policy selection", url };
  } else if (url.includes(APP_URL_CONSTS.TRACKING)) {
    return {
      ...pageData,
      title: viewClaim,
      breadcrumbLabel: viewClaim,
      url,
      showHelpButton: true,
    };
  } else {
    return { ...pageData, showHelpButton: true };
  }
};

const getTitle = (product: string) => {
  switch (product) {
    case "GIP":
      return GroupIncomeJourney;
    case "GLI":
      return GroupLifeJourney;
    case "GIPVRS":
      return RehabJourney;
    case "GCI":
      return CICJourney;
    default:
      return makeAClaim;
  }
};

const showHelpForProduct = (url: string, product: string): boolean => {
  return havingHelpUrls.some(
    (page) => page.url === url && !page?.excludedProducts?.includes(product)
  );
};

const getComonPageData = (url: string, product: string) => {
  if (mainUrls.includes(url)) {
    return getMainURLsPageData(url);
  } else {
    const page = { ...pageData, title: getTitle(product), url };
    if (showHelpForProduct(url, product)) {
      return { ...page, showHelpButton: true };
    } else {
      return { ...page, showHelpButton: false };
    }
  }
};

export const matchUrlToPage = (url: string, productType = ""): page | null => {
  return getComonPageData(url, productType);
};
