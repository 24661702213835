import React from "react";
import {
  StyledContainer,
  StyledBannerImage,
  StyledLowerBanner,
  StyledIcon,
  StyledHeader,
  StyledSubHeader,
  StyledLowerBannerInside,
} from "./PromotionalBanner.styles";
import { ReactComponent as RightArrow } from "../../../assets/icons/Right-Arrow-White.svg";

type PromotionalBannerProps = {
  header: string;
  headerAltText: string;
  subHeader: string;
  image: string;
  CallBack?: () => void;
};
export const PromotionalBanner: React.FC<PromotionalBannerProps> = ({
  header,
  headerAltText,
  subHeader,
  image,
  CallBack,
}) => {
  return (
    <StyledContainer>
      <StyledBannerImage>
        <img src={image} alt={headerAltText} />
      </StyledBannerImage>
      <StyledLowerBanner>
        <StyledLowerBannerInside>
          <StyledHeader>{header}</StyledHeader>
          <StyledSubHeader>{subHeader}</StyledSubHeader>
        </StyledLowerBannerInside>
        {CallBack && (
          <StyledIcon>
            <a id="icon" data-testid="iconclicktest" onClick={CallBack}>
              <RightArrow />
            </a>
          </StyledIcon>
        )}
      </StyledLowerBanner>
    </StyledContainer>
  );
};
