import style from "./navLogout.module.scss";
import navIconStyle from "../NavItem/NavItem.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
type navLogoutProps = {
  isExpanded: boolean;
  onLogout: () => void;
};

function NavLogout({ isExpanded, onLogout }: Readonly<navLogoutProps>) {
  const NIMain = classNames(navIconStyle.NI_Main, {
    [navIconStyle.expanded]: isExpanded,
  });

  return (
    <button
      type="button"
      className={`${NIMain} ${style.NV_Button}`}
      name="BTN_Logout"
      aria-label="BTN_Logout"
      onClick={(e) => {
        e.preventDefault();
        onLogout();
      }}
    >
      <div className={navIconStyle.NI_Icon}>
        <FontAwesomeIcon icon={faRightFromBracket} />
      </div>
      <div className={navIconStyle.NI_Extra}>Logout</div>
    </button>
  );
}

export default NavLogout;
