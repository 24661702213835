import React from "react";
import {
  StyledGreetingBody,
  StyledGreeting,
  StyledGreetingBold,
  StyledHeadingContainer,
} from "./Greetings.styles";

type GreetingsProps = {
  initialGreeting?: string;
  name?: string;
  body?: string;
};

const Greetings: React.FC<GreetingsProps> = ({
  initialGreeting,
  name,
  body,
}) => {
  return (
    <div>
      <StyledHeadingContainer>
        <StyledGreeting>{initialGreeting}</StyledGreeting>
        <StyledGreetingBold>{name}</StyledGreetingBold>
      </StyledHeadingContainer>

      <StyledGreetingBody>{body}</StyledGreetingBody>
    </div>
  );
};
export default Greetings;
