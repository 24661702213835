/* c8 ignore start */  
import TagManager from 'react-gtm-module';

const initializeGTM = () => {
  const tagID = 'GTM-KDTFBS7D';

  const isProduction = process.env.NODE_ENV === 'production';

  // env-3 is for prod, env-4 is non-prod
  const environment = isProduction ? 'env-3' : 'env-4';
  const authcode = isProduction ? 'K5EFPADMmHNLkL7wnxTmLw' : '-_flpq3wth7Yn9PStBCfAg';
  
  const tagManagerArgs = {
    gtmId: tagID,
    auth: authcode,
    preview: environment,
  };

  TagManager.initialize(tagManagerArgs);
};

export default initializeGTM;


/* c8 ignore end */  