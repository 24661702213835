import style from "./Navbar.module.scss";
import NavItem from "./NavItem/NavItem";
import NavLogout from "./NavLogout/NavLogout";
import NavLogo from "./NavLogo/NavLogo";
import { useState, ReactNode } from "react";
import classNames from "classnames";

export type navItem = {
  id: string;
  icon: ReactNode;
  expandedSec: ReactNode;
  linkTo: string;
  openinNewTab: boolean;
  analyticsName?: string;
};

type navbarProps = {
  navItems: navItem[];
  onLogout: () => void;
};

export function Navbar({ navItems, onLogout }: navbarProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const NB_Main = classNames(style.NB_Main, {
    [style.expanded]: isExpanded,
  });
  return (
    <nav
      className={NB_Main}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      onFocus={() => setIsExpanded(true)}
      onBlur={() => setIsExpanded(false)}
      tabIndex={0}
      data-testid="Navbar-main"
      data-autoid="NAVBAR_Side"
      role="navigation"
    >
      <div className={style.NB_Logo}>
        <NavLogo isExpanded={isExpanded}></NavLogo>
      </div>
      <ol data-autoid="LIST_Side_Nav">
        {navItems.map((item) => (
          <li
            key={item.id}
            data-autoid={`ITEM_${item.linkTo
              .replace("/", "")
              .replace(" ", "_")}`}
          >
            <NavItem {...item} isExpanded={isExpanded}></NavItem>
          </li>
        ))}
      </ol>
      <div className={style.NB_Bottom}>
        <NavLogout isExpanded={isExpanded} onLogout={onLogout}></NavLogout>
      </div>
    </nav>
  );
}

export default Navbar;
