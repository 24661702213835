import React, { ReactNode } from "react";
import { StyledBentoBox, StyledChild } from "./BentoBox.styles";

type BentoBoxProps = {
  variant: "3" | "4" | "6";
  children?: ReactNode[];
};

type ChildLayout = {
  rowSpan: number;
  colSpan: number;
  mobileOrder: number;
};

export const BentoBox: React.FC<BentoBoxProps> = ({
  variant,
  children = [],
}) => {
  let gridTemplateColumns: string;
  let gridTemplateRows: string;
  let childLayouts: ChildLayout[] = [];

  // Defining Grid appearance based on variant
  switch (variant) {
    case "3":
      gridTemplateColumns = "1fr 1fr";
      gridTemplateRows = "auto auto";
      childLayouts = [
        { rowSpan: 1, colSpan: 1, mobileOrder: 1 },
        { rowSpan: 2, colSpan: 1, mobileOrder: 3 },
        { rowSpan: 1, colSpan: 1, mobileOrder: 2 },
      ];
      break;
    case "4":
      gridTemplateColumns = "1fr 1fr 1fr 1fr";
      gridTemplateRows = "auto auto";
      childLayouts = [
        { rowSpan: 1, colSpan: 2, mobileOrder: 1 },
        { rowSpan: 2, colSpan: 2, mobileOrder: 4 },
        { rowSpan: 1, colSpan: 1, mobileOrder: 2 },
        { rowSpan: 1, colSpan: 1, mobileOrder: 3 },
      ];

      break;
    case "6":
      gridTemplateColumns = "1fr 1fr 1fr 1fr";
      gridTemplateRows = "auto auto";
      childLayouts = [
        { rowSpan: 1, colSpan: 2, mobileOrder: 1 },
        { rowSpan: 2, colSpan: 1, mobileOrder: 6 },
        { rowSpan: 1, colSpan: 1, mobileOrder: 2 },
        { rowSpan: 1, colSpan: 1, mobileOrder: 3 },
        { rowSpan: 1, colSpan: 1, mobileOrder: 4 },
        { rowSpan: 1, colSpan: 1, mobileOrder: 6 },
      ];
      break;
    default:
      gridTemplateColumns = "1fr";
      gridTemplateRows = "auto";
      childLayouts = [{ rowSpan: 1, colSpan: 1, mobileOrder: 1 }];
  }

  return (
    <StyledBentoBox
      gridTemplateColumns={gridTemplateColumns}
      gridTemplateRows={gridTemplateRows}
    >
      {children.map((child, index) => (
        <StyledChild
          key={index}
          rowSpan={childLayouts[index]?.rowSpan}
          colSpan={childLayouts[index]?.colSpan}
          mobileOrder={childLayouts[index]?.mobileOrder}
        >
          {child}
        </StyledChild>
      ))}
    </StyledBentoBox>
  );
};
