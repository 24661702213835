import { AnyAction, ThunkAction } from "@reduxjs/toolkit";

import ClaimSlice from "../slices/claimSlice";
import { Claim } from "../../models/ClaimModel";
import { getClaims } from "../../services/claimsAPI";
import { RootState } from "../store";

export const claimActions = ClaimSlice.actions;

export const getCliamsAction = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (dispatch, getState) => {
    const response: Claim[] = await getClaims();
    dispatch(claimActions.setClaims(response));
  };
};

export const updatePolicyDetls = (
  policyDetls: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(claimActions.setPolicyDetls(policyDetls));
  };
};

export const clearPolicyDetls = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (dispatch, getState) => {
    dispatch(claimActions.clearPolicyDetls());
  };
};

export const updateClaimId = (
  claimId: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(claimActions.setClaimId(claimId));
  };
};

export const updateLifeId = (
  lifeId: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(claimActions.setLifeId(lifeId));
  };
};

export const getBespokeData = (
  bespokeData: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(claimActions.setBespokeData(bespokeData));
  };
};

export const setPageError = (
  set = false
): ThunkAction<void, RootState, unknown, AnyAction> => {
  if (set) {
    return async (dispatch, getState) => {
      dispatch(claimActions.setPageError());
    };
  } else {
    return async (dispatch, getState) => {
      dispatch(claimActions.removePageError());
    };
  }
};

export const setAPIError = (
  apiError: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(claimActions.setAPIError(apiError));
  };
};

export const removeAPIError = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (dispatch, getState) => {
    dispatch(claimActions.removeAPIError());
  };
};

export const setLoading = (
  message: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(claimActions.setLoading(message));
  };
};

export const resetLoading = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (dispatch, getState) => {
    dispatch(claimActions.resetLoading());
  };
};

export const updateRhabWorkInstanceId = (
  claimId: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(claimActions.setWorkInstanceId(claimId));
  };
};

export const updateCompanyName = (
  companyName: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(claimActions.setCompanyName(companyName));
  };
};

export const setAssignUserFlag = (
  flag: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(claimActions.setAssignUserFlag(flag));
  };
};

export const updateCompanyId = (
  companyId: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(claimActions.setCompanyId(companyId));
  };
};
