import React, { useEffect, lazy, useState, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { filterNavList, NavList } from "./navItems";
import { useAnalytics } from "../hooks/Analytics/Analytics";
import initializeGTM from "../hooks/Analytics/gtm";
import Homepage from "../pages/Homepage/Homepage";

import { useAppDispatch, useAppSelector } from "../hooks/reduxhooks";

import { setPageError, resetLoading } from "../redux/actions/claimsAction";
import PrivateRoute from "./PrivateRoute";

import APP_URL_CONSTS from "./AppUrls";
import IntrnalPrivateRoute from "./InternalPrivateRoute";
import {
  claimSubmissionRouting,
  claimSummaryRouting,
  eligibilityReouting,
  employeeDetailsReouting,
  paymentRouting,
  WorkDetailsRouting,
} from "./RoutingData";
import { appInsights } from "../ApplicationInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

import useGetUser from "../hooks/useGetUser";
import { navItem } from "../components/Layout/Navbar/Navbar";
import Callback from "../pages/Callback/Callback";
import Logout from "../pages/Logout/Logout";

const ClaimsDashboard = lazy(
  () => import("../pages/ClaimsDashboard/ClaimsDashboard")
);

const PolicySelection = lazy(
  () => import("../pages/PolicySelection/PolicySelection")
);

const GIP_AbsenceDetails = lazy(
  () => import("../pages/GIP/AbsenceDetails/AbsenceDetails")
);
const ClaimsTracking = lazy(
  () => import("../pages/ClaimsTracking/ClaimsTracking")
);

const GIP_ClientQuestions = lazy(
  () => import("../pages/GIP/ClientQuestions/ClientQuestions")
);
const GIP_VocationalRehabSupport = lazy(
  () => import("../pages/GIP/VocationalRehabSupport/VocationalRehabSupport")
);

const GL_DeceasedDetails = lazy(
  () => import("../pages/GL/DeceasedDetails/DeceasedDetails")
);

const REHAB_AbsenceManagementSupport = lazy(
  () => import("../pages/REHAB/AbsenceManagementSupport/EmployeeSupport")
);

const CIC_ClaimDetails = lazy(
  () => import("../pages/CIC/ClaimDetails/ClaimDetails")
);
const ErrorPage = lazy(() => import("../pages/ErrorBoundary/ErrorPage"));

const RouteConfig: React.FC = () => {
  useAnalytics();
  initializeGTM();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { appAccess } = useGetUser();

  const [nav, setNav] = useState<navItem[]>([]);
  useEffect(() => {
    setNav([...filterNavList(appAccess, NavList)]);
  }, [appAccess]);

  useEffect(() => {
    window.addEventListener("error", (event) => {
      appInsights.trackException({
        error: event.error,
        severityLevel: SeverityLevel.Error,
      });
      appInsights.trackTrace({
        message: event.error.stack,
        severityLevel: SeverityLevel.Error,
      });

      dispatch(setPageError(true));
      dispatch(resetLoading());
      navigate(`${APP_URL_CONSTS.ERRORPAGE}`);
    });

    window.addEventListener(
      "unhandledrejection",
      (event: PromiseRejectionEvent) => {
        appInsights.trackException({
          error: event.reason,
          severityLevel: SeverityLevel.Error,
        });

        dispatch(setPageError(true));
        dispatch(resetLoading());
        navigate(`${APP_URL_CONSTS.ERRORPAGE}`);
      }
    );

    return () => {
      window.removeEventListener("error", (event) => {});

      window.removeEventListener(
        "unhandledrejection",
        (event: PromiseRejectionEvent) => {}
      );
    };
  }, []);

  const policyDetls: any = useAppSelector(
    (state: any) => state.ClaimSlice.policyDetls
  );

  return (
    <Suspense>
      <Routes>
        <Route path={"/"} element={<Callback />} />
        <Route path={"/logout"} element={<Logout />} />
        <Route element={<PrivateRoute />}>
          <Route element={<Layout logoutRedirect={"/logout"} navItems={nav} />}>
            <Route path={APP_URL_CONSTS.HOMEPAGE} element={<Homepage />} />
            <Route
              path={APP_URL_CONSTS.CLAIMS + "/:tab?"}
              element={
                <PrivateRoute //Restrict access as per story 175477
                  expectedRoles={["user", "super-user", "admin", "enhanced"]}
                />
              }
            >
              <Route index element={<ClaimsDashboard />} />
              <Route
                path={APP_URL_CONSTS.POLICY_SELECTION}
                element={<PolicySelection />}
              />
              {/* Top level Routing */}
              <Route
                path={APP_URL_CONSTS.TRACKING}
                element={
                  <PrivateRoute
                    expectedRoles={["super-user", "admin", "enhanced"]}
                  />
                }
              >
                <Route index element={<ClaimsTracking />} />
              </Route>
              {/* Top level Routing */}
              {/* GIP Routes... */}
              <Route element={<IntrnalPrivateRoute />}>
                <Route
                  path={APP_URL_CONSTS.EMPLOYEE_DETAILS}
                  element={employeeDetailsReouting(policyDetls.product)}
                />
                <Route
                  path={APP_URL_CONSTS.DECEASED_DETAILS}
                  element={<GL_DeceasedDetails />}
                />
                <Route
                  path={APP_URL_CONSTS.ELIGIBILITY_DETAILS}
                  element={eligibilityReouting(policyDetls.product)}
                />
                <Route
                  path={APP_URL_CONSTS.OTHER_DETAILS}
                  element={eligibilityReouting(policyDetls.product)}
                />
                <Route
                  path={APP_URL_CONSTS.WORK_DETAILS}
                  element={WorkDetailsRouting(policyDetls.product)}
                />
                <Route
                  path={APP_URL_CONSTS.ABSENCE_DETAILS}
                  element={<GIP_AbsenceDetails />}
                />
                <Route
                  path={APP_URL_CONSTS.PAYMENT_DETAILS}
                  element={paymentRouting(policyDetls.product)}
                />
                <Route
                  path={APP_URL_CONSTS.VOC_REHB_SUPPORT}
                  element={<GIP_VocationalRehabSupport />}
                />
                <Route
                  path={APP_URL_CONSTS.CLIENT_QUESTIONS}
                  element={<GIP_ClientQuestions />}
                />
                <Route
                  path={APP_URL_CONSTS.CLAIM_SUMMARY}
                  element={claimSummaryRouting(policyDetls.product)}
                />
                <Route
                  path={APP_URL_CONSTS.SUBMISION_CONFRIMSN}
                  element={claimSubmissionRouting(policyDetls.product)}
                />
                <Route
                  path={APP_URL_CONSTS.CLAIM_DETAILS}
                  element={<CIC_ClaimDetails />}
                />
                <Route
                  path={APP_URL_CONSTS.ABSENC_MNGT_SPRT}
                  element={<REHAB_AbsenceManagementSupport />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route
          path={APP_URL_CONSTS.ERRORPAGE}
          element={<ErrorPage pageNotFound={false} />}
        />
        <Route path={"*"} element={<ErrorPage pageNotFound={true} />} />
      </Routes>
    </Suspense>
  );
};

export default RouteConfig;
