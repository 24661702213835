export const forgerockRoleKey =
  "urn:ciamuk-v1:non-unique-attribute:employer:shared:simple-entitlement-v1"

export const forgerockCashplanRoleKey =
  "urn:ciamuk-v1:non-unique-attribute:employer:cashplan:simple-entitlement-v1"


export const cashplanRoleScope = "uuk-cashplan-employer";

export const userRoles = {
  normal: "user",
  admin: "admin",
  super: "super-user",
  enhanced: "enhanced"
} as const;

export type userRolesType =
  | (typeof userRoles)[keyof typeof userRoles]
  | undefined;
