import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks/reduxhooks";
import { matchUrlToPage } from "../../pages/Pages";
import { OAuth2Client } from "@forgerock/javascript-sdk";
import { clearUser } from "../../redux/slices/userSlice";
import style from "./Layout.module.scss";
import { Navbar, navItem } from "./Navbar/Navbar";
import TopMenu from "./TopMenu/TopMenu";
import { Suspense } from "react";
type layoutProps = {
  navItems: navItem[];
  logoutRedirect: string;
};

function Layout({ navItems, logoutRedirect }: layoutProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const policyDetls: any = useAppSelector(
    (state: any) => state.ClaimSlice.policyDetls
  );
  let title = "Employer Portal";
  let showHelp = false;
  const currentPage = matchUrlToPage(location.pathname, policyDetls.product);
  if (currentPage) {
    title = currentPage.title;
    showHelp = currentPage.showHelpButton;
  }
  return (
    <>
      {
        <header className={style.LayoutHeader}>
          <TopMenu title={title} showHelp={showHelp}></TopMenu>
        </header>
      }
      <div className={style.LayoutSide}>
        <Navbar
          navItems={navItems}
          onLogout={async () => {
            await OAuth2Client.endSession();
            dispatch(clearUser());
            navigate(logoutRedirect);
          }}
        />
      </div>
      <div className={style.LayoutBackground}></div>
      <main className={style.LayoutContent} style={{ paddingTop: "60px" }}>
        <div className={style.CenterSection}>
          <Suspense fallback={<div style={{ margin: "10px" }}>Loading...</div>}>
            <Outlet />
          </Suspense>
        </div>
      </main>
    </>
  );
}

export default Layout;
