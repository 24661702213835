import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-common";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: `${process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION}`,
    correlationHeaderExcludedDomains: [
      process.env.REACT_APP_APPINSIGHTS_EXCLUDEDOMAIN as string,
    ],
    extensions: [reactPlugin as any],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: false, // NOTE Should not be tracked in logs
    enableResponseHeaderTracking: false, // NOTE Should not be tracked in logs
  },
});

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || [];
  env.tags["ai.cloud.role"] = "EmployerPortal_UI";
  const data = env.baseData;

  if (
    data?.target &&
    (data?.target.includes("token") ||
      data?.target.includes("forgerock") ||
      data?.target.includes("oauth"))
  ) {
    return false;
  }
  return true;
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || [];
  env.tags["ai.cloud.role"] = "EmployerPortal_UI";
  const data = env.baseData;

  if (
    data?.target &&
    (data?.target.includes("token") ||
      data?.target.includes("forgerock") ||
      data?.target.includes("oauth"))
  ) {
    return false;
  }
  return true;
});

const logToInsights = (message: any) => {
  appInsights.trackTrace({
    message,
    SeverityLevel: SeverityLevel.Information,
  } as any);
};

const logErrorToInsights = (message: any) => {
  appInsights.trackTrace({
    message,
    SeverityLevel: SeverityLevel.Error,
  } as any);
};

const logExceptionToInsights = (transactionId = "", error: any) => {
  appInsights.trackException({
    id: transactionId,
    error,
    severityLevel: SeverityLevel.Critical,
  });
};
export {
  reactPlugin,
  appInsights,
  logToInsights,
  logErrorToInsights,
  logExceptionToInsights,
};
