import breadcrumbSlice from "./breadcrumbSlice";
import ClaimSlice from "./claimSlice";
import HelpPanelReducer from "./HelpPanelSlice";
import cmsLoadingSlice from "./cmsLoadingSlice";
import userSliceReducer from "./userSlice";

const reducers = {
  ClaimSlice: ClaimSlice.reducer,
  HelpPanelSlice: HelpPanelReducer,
  UserSlice: userSliceReducer,
  BreadcrumbSlice: breadcrumbSlice,
  CmsLoading: cmsLoadingSlice,
};

export default reducers;
