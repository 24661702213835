import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Claim } from "../../models/ClaimModel";

const initialClaimsState: any = {
  claimsList: [],
  policyDetls: {},
  claimId: "",
  lifeId: "",
  bespokeData: {},
  pageError: false,
  apiError: "",
  loadingData: { loading: false, message: "Loading" },
  workInstanceId: "",
  companyName: "",
  companyId: "",
  assignUserFlag: false,
};

const claimSlice = createSlice({
  name: "claims",
  initialState: initialClaimsState,
  reducers: {
    setClaims: (state, action: PayloadAction<Claim[]>) => {
      state.claimsList = action.payload;
    },
    setPolicyDetls: (state, action: PayloadAction<any>) => {
      state.policyDetls = action.payload;
    },
    setClaimId: (state, action: PayloadAction<string>) => {
      state.claimId = action.payload;
    },
    setLifeId: (state, action: PayloadAction<string>) => {
      state.lifeId = action.payload;
    },
    setBespokeData: (state, action: PayloadAction<any>) => {
      state.bespokeData = action.payload;
    },
    clearPolicyDetls: (state) => {
      state.policyDetls = {};
    },
    setPageError: (state) => {
      state.pageError = true;
    },
    removePageError: (state) => {
      state.pageError = false;
    },
    setAPIError: (state, action: PayloadAction<string>) => {
      state.apiError = action.payload;
    },
    removeAPIError: (state) => {
      state.apiError = "";
    },
    setLoading: (state, action: PayloadAction<string>) => {
      state.loadingData = { loading: true, message: action.payload };
    },
    resetLoading: (state) => {
      state.loadingData = { loading: false, message: "" };
    },
    setWorkInstanceId: (state, action: PayloadAction<string>) => {
      state.workInstanceId = action.payload;
    },
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload;
    },
    setCompanyId: (state, action: PayloadAction<string>) => {
      state.companyId = action.payload;
    },
    setAssignUserFlag: (state, action: PayloadAction<boolean>) => {
      state.assignUserFlag = action.payload;
    },
  },
});

export default claimSlice;
