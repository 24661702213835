import style from "./LoginSpinner.module.scss";
import UnumLogo from "../../components/UnumLogo/UnumLogo";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoginSpinner = () => {
  return (
    <div className={style.Main}>
      <div className={style.LoginCard}>
        <div className={style.LoginLogo}>
          <UnumLogo
            primaryColor="var(--unumBlue)"
            secondaryColor="var(--unumBlue)"
            height="70px"
            width="100%"
          />
        </div>
        <div className={style.LoginIconWrapper}>
          <FontAwesomeIcon icon={faUser} className={style.LoginAvatar} />
          {<div className={style.LoginLoader} />}
        </div>
        <main className={style.LoginBottom}>
          <p className={style.LoginWarningText}>
            You will be redirected to login then brought back to the portal
          </p>
        </main>
      </div>
    </div>
  );
};

export default LoginSpinner;
