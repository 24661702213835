import {
  StyledSimpleTextBody,
  StyledSimpleText,
  StyledIcon,
  Styledcontainer,
} from "./SimpleText.styles";
import { ReactComponent as RightArrowBlue } from "../../../assets/icons/Right-Arrow-Blue.svg";
import { ReactComponent as RightArrowGreen } from "../../../assets/icons/Right-Arrow-Green.svg";
import { FC } from "react";

type SimpleTextProps = {
  initialGreetings: string;
  RedirectCallBack?: () => void;
  body: string;
  variant?: "primary" | "secondary";
  style?: React.CSSProperties;
};

export const SimpleText: FC<SimpleTextProps> = ({
  initialGreetings,
  RedirectCallBack,
  body,
  variant = "primary",
  style,
}) => {
  return (
    <Styledcontainer
      variant={variant}
      style={style}
      data-testid="iconclicktest"
      onClick={RedirectCallBack}
    >
      <StyledSimpleText variant={variant}>{initialGreetings}</StyledSimpleText>
      <StyledSimpleTextBody>{body}</StyledSimpleTextBody>
      <StyledIcon variant={variant}>
        {variant === "primary" ? <RightArrowBlue /> : <RightArrowGreen />}
      </StyledIcon>
    </Styledcontainer>
  );
};
