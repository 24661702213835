import { UserManager } from "@forgerock/javascript-sdk";
import { setUser } from "../../redux/slices/userSlice";
import {
  checkCashplanAccess,
  getRoleFromAttributes,
} from "../../utils/User/Roles";
import { useAppDispatch } from "../reduxhooks";

const useUpdateUserInfomation = () => {
  const dispatch = useAppDispatch();
  const updateUser = async () => {
    const user: any = await UserManager.getCurrentUser();
    dispatch(
      setUser({
        firstName: user.given_name,
        lastName: user.family_name,
        email: user.email,
        userId: user.subname,
        role: getRoleFromAttributes(user["non-unique-attribute"]),
        jobTitle: user.job_title,
        PhoneNumber: user.phone_number,
        hasCashplanAccess: checkCashplanAccess(user["non-unique-attribute"]),
        isAuthenticated: true,
      })
    );
    return user;
  };

  return { updateUser };
};

export default useUpdateUserInfomation;
