/* c8 ignore start */  
import ga4 from 'react-ga4'

let TRACKING_ID = "";

switch(process.env.REACT_APP_ENV) {
  case 'Development':
    TRACKING_ID = "G-R75E316FMS";
    break;
  case 'SystemTest':
    TRACKING_ID = "G-EYF0Y26V9E";
    break;
  case 'PreProd ':
    TRACKING_ID = "G-3J712HPYNC";
    break;
  case 'Production':
    TRACKING_ID = "G-VBB4D56XMK";
    break;
  default: 
    TRACKING_ID = "N/A";
}

export const init = () => { 
  ga4.initialize(TRACKING_ID);
  ga4.gtag('config', TRACKING_ID, {
    cookie_flags: 'secure;samesite=none'
  });
}


export const sendPageview = (path: string) => ga4.send({ 
  hitType: 'pageview', 
  page: path,
  title: path
})

export const sendPageEvent = (category: string, action : string, label : string) => ga4.event({ 
  category: category,
  action: action,
  label: label,
})
/* c8 ignore end */  
