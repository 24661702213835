import { Navigate, Outlet } from "react-router-dom";
import APP_URL_CONSTS from "./AppUrls";
import { prodsArray } from "../types/enums/Products/Products";
import { useAppSelector } from "../hooks/reduxhooks";

const IntrnalPrivateRoute = ({
  redirectPath = `${APP_URL_CONSTS.CLAIMS}/${APP_URL_CONSTS.POLICY_SELECTION}`,
}: any) => {
  const policyDetls: any = useAppSelector(
    (state: any) => state.ClaimSlice.policyDetls
  );

  const isAuthenticated = () => {
    return policyDetls && prodsArray.includes(policyDetls.product)
      ? true
      : false;
  };

  if (!isAuthenticated()) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default IntrnalPrivateRoute;
