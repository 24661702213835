import { AxiosRequestConfig } from "axios";
import { v4 as uuid } from "uuid";

const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const DELETE = "DELETE";

export const baseUrl = process.env.REACT_APP_BASE_URL;
const metaDataUrl = "/ClaimMetadata/v1";
const claimSearchUrl = "/ClaimSearch/v1";
const claimTrackinghUrl = "/ClaimTracking/v1";
const assignUserUrl = "/AssignClaimMetadata/v1";

export const postCodeApi = {
  getUrl: (zipCode: string): string => {
    return `/AddressService/v2/${zipCode}`;
  },
  headers: {
    TransactionId: uuid(),
  },
  url: `/AddressService/v2/{0}`,
  method: GET,
};

export const companyDetailApi = {
  headers: {
    TransactionId: uuid(),
  },
  url: `${baseUrl}/CompanyDetail/v1`,
  method: GET,
};

export const claimsListApi = () => {
  return {
    url: `${baseUrl}/ClaimList/v1`,
    headers: {
      TransactionId: uuid(),
    },
    method: GET,
  };
};

export const deleteClaimApi = (claimId: string, companyId: string): AxiosRequestConfig => {
  return {
    url: baseUrl + metaDataUrl,
    data: {
        claimId,
        companyId,
    },
    method: DELETE,
  };
};

export const saveGIPClaimPage1 = {
  headers: {
    TransactionId: uuid(),
  },
  url: baseUrl + metaDataUrl,
  method: POST,
};

export const saveGIPClaimPage3 = {
  headers: {
    TransactionId: uuid(),
  },
  url: baseUrl + metaDataUrl,
  method: PUT,
};

export const updateGIPClaim = {
  headers: {
    TransactionId: uuid(),
  },
  url: baseUrl + metaDataUrl,
  method: PUT,
};

export const getGIPClaim = {
  headers: {
    TransactionId: uuid(),
  },
  url: baseUrl + metaDataUrl,
  method: GET,
};

export const getBespokeQuestionsApi = {
  headers: {
    TransactionId: uuid(),
    SelectedProduct: "GIP", // static for now
  },
  url: `${baseUrl}/BespokeQuestions/v1`,
  method: GET,
};

const documentURL = baseUrl + "/Documents/v1";
export const uploadDocument = (
  data: {
    claimId: string;
    documentId: string;
    documentName: string;
    documentData: any;
  },
  onUploadProgress: (progressEvent: any) => void
) => {
  const formData = new FormData();
  const headers = {
    TransactionID: uuid(),
    claimId: data.claimId,
    documentId: data.documentId,
    fileName: data.documentName,
  };
  formData.append("DocumentData", data.documentData);

  return {
    url: baseUrl + "/Documents/v1",
    method: POST,
    onUploadProgress,
    headers: {
      ...headers,
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };
};

export const uploadAWDDoc = (
  data: {
    claimId: string;
    documentId: string;
    documentName: string;
    documentData: any;
  },
  claimHeaders: {
    claimId: string;
    lifeId: string;
    policyNo: string;
    productType: string;
  },
  onUploadProgress: (progressEvent: any) => void
) => {
  const formData = new FormData();

  formData.append("DocumentData", data.documentData);
  //Using the below URL for testing. Will be removed and used actual one once deployment is done.
  return {
    timeout: 60000,
    url: baseUrl + "/AWDClaimWorkItem/v1",
    method: PUT,
    onUploadProgress,
    headers: {
      documentId: data.documentId,
      fileName: data.documentName,
      "Content-Type": "multipart/form-data",
      ...claimHeaders,
      requestType: "DocumentUpload",
      TransactionID: uuid(),
    },
    data: formData,
  };
};

export const deleteDocument = (documentId: string) => {
  return {
    url: documentURL,
    method: DELETE,
    data: {
      transactionId: uuid(),
      documentId,
    },
  };
};
export const getDocument = (claimId: string) => ({
  url: documentURL,
  method: GET,
  headers: {
    TransactionId: uuid(),
    ClaimId: claimId,
  },
});

export const getClaimStatusHistory = (body: any) => ({
  url: `${baseUrl}/ClaimSearch/v1`,
  method: POST,

  data: body,
});

//This API is re-usable for multiple claims tracking requests
export const claimSearchAPI = (data: {
  claimId?: string;
  lifeId?: string;
  policyNo?: string;
  workReferenceId?: string;
  productType: string;
  requestType: string;
  workInstanceId?: string; //150763
}) => {
  return {
    url: baseUrl + claimSearchUrl,
    method: POST,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      ...data,
      transactionId: uuid(),
    },
  };
};

export const getClaimTracking = (
  lifeId: string,
  claimId: string,
  selectedPolicyNumber: string
) => ({
  url: baseUrl + claimTrackinghUrl,
  method: GET,
  headers: {
    TransactionId: uuid(),
    LifeId: lifeId,
    ClaimId: claimId,
    SelectedPolicyNumber: selectedPolicyNumber,
  },
});

export const submitClaim = (data: {
  claimId: string;
  policyNo: string;
  productTypeModel: string;
  requestTypeModel: string;
}) => ({
  url: baseUrl + "/AWDClaimWorkItem/v1",
  method: POST,
  data: {
    ...data,
    transactionId: uuid(),
  },
});

export const getPrivilegedUsersApi = {
  headers: {
    TransactionId: uuid(),
  },
  url: `${baseUrl}/PrivilegedUsers/v1`,
  method: GET,
};

export const getAllUsersApi = {
  headers: {
    TransactionId: uuid(),
  },
  url: `${baseUrl}/UserPolicies/v1`,
  method: GET,
};

export const assignUserPostApi = (data: any) => ({
  url: baseUrl + assignUserUrl,
  method: POST,
  data: {
    ...data,
    transactionId: uuid(),
  },
});

export const assignUserPutApi = (data: any) => ({
  url: baseUrl + assignUserUrl,
  method: PUT,
  data: {
    ...data,
    transactionId: uuid(),
  },
});
