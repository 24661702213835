import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type breadcrumbSliceType = {
  pageHistory: string[];
};

const initialBreadcrumbState: breadcrumbSliceType = {
  pageHistory: [],
};

const breadcrumbSlice = createSlice({
  name: "bradcrumb",
  initialState: initialBreadcrumbState,
  reducers: {
    setPageHistory: (state, action: PayloadAction<string[]>) => {
      state.pageHistory = action.payload;
    },
    clearPageHistory: (state) => {
      state.pageHistory = [];
    },
  },
});

export const { setPageHistory, clearPageHistory } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
