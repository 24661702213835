/* c8 ignore start */

import { FC } from "react";
import Drawer from "@mui/material/Drawer";

export interface IDrawerProps {
  children?: React.ReactNode;
  open: boolean;
  setOpen?: () => void;
}

const DrawerBanner: FC<IDrawerProps> = ({ children, open }) => (
  <Drawer anchor={"bottom"} open={open} variant="persistent">
    {children}
  </Drawer>
);

export default DrawerBanner;
/* c8 ignore end */
