type unumLogoProps = {
  height?: string;
  width?: string;
  primaryColor?: string;
  secondaryColor?: string;
};

const UnumLogo = ({
  height = "87",
  width = "87",
  primaryColor = "white",
  secondaryColor = "white",
}: unumLogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 87 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.4 28.7001C55.6 28.7001 57.2 24 57.2 20.8V11.3H51.9V20.4C51.9 22.9 50.5 24 48.4 24C46.3 24 44.9 22.9 44.9 20.4V11.3H39.6V20.8C39.5 24 41.1 28.7001 48.4 28.7001Z"
        fill={primaryColor}
      />
      <path
        d="M8.8 28.7001C16 28.7001 17.6 24 17.6 20.8V11.3H12.3V20.4C12.3 22.9 10.9 24 8.8 24C6.7 24 5.3 22.9 5.3 20.4V11.3H0V20.8C0 24 1.6 28.7001 8.8 28.7001Z"
        fill={primaryColor}
      />
      <path
        d="M28.6 10.7C21.4001 10.7 19.8 15.4 19.8 18.6V28H25.1V18.9C25.1 16.4 26.5001 15.3 28.6 15.3C30.7001 15.3 32.1 16.4 32.1 18.9V28H37.4001V18.5C37.4001 15.3 35.8 10.7 28.6 10.7Z"
        fill={primaryColor}
      />
      <path
        d="M73.1 13.3001C72.9 13.0001 71.5 10.6001 67.2 10.6001C62.8 10.6001 59.3 12.8001 59.3 18.2001V28.0001H64.5V18.2001C64.5 16.5001 65.4 15.2001 67.5 15.2001C69.6 15.2001 70.5 16.5001 70.5 18.2001V28.0001H75.7V18.2001C75.7 16.5001 76.6 15.2001 78.7 15.2001C80.8 15.2001 81.7 16.5001 81.7 18.2001V28.0001H87V18.2001C87 12.9001 83.5 10.6001 79.1 10.6001C74.7 10.7001 73.4 13.0001 73.1 13.3001Z"
        fill={primaryColor}
      />
      <path
        d="M24.2 4.3C24.2 6.7 26.1 8.6 28.6 8.6C31 8.6 32.9 6.7 32.9 4.3C32.9 1.9 31 0 28.6 0C26.2 0 24.2 1.9 24.2 4.3Z"
        fill={secondaryColor}
      />
      <path
        d="M44 4.3C44 6.7 45.9 8.6 48.4 8.6C50.8 8.6 52.7 6.7 52.7 4.3C52.7 1.9 50.8 0 48.4 0C45.9 0 44 1.9 44 4.3Z"
        fill={secondaryColor}
      />
      <path
        d="M68.8 4.3C68.8 6.7 70.7 8.6 73.2 8.6C75.6 8.6 77.5 6.7 77.5 4.3C77.5 1.9 75.6 0 73.2 0C70.7 0 68.8 1.9 68.8 4.3Z"
        fill={secondaryColor}
      />
    </svg>
  );
};

export default UnumLogo;
