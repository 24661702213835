import { ThemeProvider } from "@mui/material/styles";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import Cookies from "js-cookie";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import { theme } from "./assets/styles/Common/MUITheme";
import Drawer from "./components/Controls/Drawer/DrawerBanner";
import { CookiesBanner } from "./components/CookiesBanner/CookiesBanner";
import { useAppSelector } from "./hooks/reduxhooks";
import RouteConfig from "./navigation/RouteConfig";
import ScrollToTop from "./utils/ScrollToTop";
import LoadingCircles from "./components/LoadingCircles/LoadingCircles";
import { reactPlugin } from "./ApplicationInsights";

function App() {
  const isAuthed = useAppSelector((state) => state.userSlice.isAuthenticated);
  const [openDrawer, setOpenDrawer] = useState(() => {
    const cookiesAccepted = Cookies.get("employerPortal_cookiesAccepted");
    if (cookiesAccepted) {
      return false;
    }
    return true;
  });
  const isLoading = useAppSelector(
    (state) => state.ClaimSlice.loadingData.loading
  );
  const handleDrawerChange = () => {
    setOpenDrawer(false);
  };

  return (
    <div className="App">
      <AppInsightsContext.Provider value={reactPlugin}>
        <ThemeProvider theme={theme}>
          {/* if not authed then dont show cookie banner */}
          {isAuthed && (
            <Drawer open={openDrawer}>
              <CookiesBanner handleDrawerChange={handleDrawerChange} />
            </Drawer>
          )}

          <BrowserRouter>
            {isLoading && <LoadingCircles isLoading={isLoading} />}
            <ScrollToTop />
            <RouteConfig />
          </BrowserRouter>
        </ThemeProvider>
      </AppInsightsContext.Provider>
    </div>
  );
}

export default App;
