import styled, { css } from "styled-components";

interface SimpleTextProps {
  variant: "primary" | "secondary";
}

export const StyledSimpleTextBody = styled.div`
  color: #2e2e2e;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  gap: 10px;
  align-self: stretch;
  padding: 0px 24px;
`;

export const StyledSimpleText: any = styled.div<SimpleTextProps>`
  padding: 24px 24px 0px 24px;
  ${({ variant }) =>
    variant === "primary" &&
    css`
      color: var(--unumBlue);
    `}

  ${({ variant }) =>
    variant === "secondary" &&
    css`
      color: var(--unumLawn);
    `}
      font-family: Ubuntu;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  gap: 10px;
  align-self: stretch;
`;

export const Styledcontainer: any = styled.button<
  SimpleTextProps & { maxWidth: string }
>`
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-4, 16px);
  width: 100%;
  border-radius: var(--Radius-6, 24px);
  border-width: 0px;
  text-align: start;
  cursor: pointer;
  ${({ variant }) =>
    variant === "primary" &&
    css`
      background: var(--unumBlue100);
    `}
  ${({ variant }) =>
    variant === "secondary" &&
    css`
      background: var(--feedbackSuccess100);
    `}
`;

export const StyledIcon: any = styled.div<SimpleTextProps>`
  margin-top: auto;
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px 8px 24px;
  border-radius: 0px 0px var(--Radius-6, 24px) var(--Radius-6, 24px);

  transition: all 0.5s;

  ${Styledcontainer}:focus &, ${Styledcontainer}:hover & {
    & svg path {
      fill: white;
    }

    ${({ variant }) =>
      variant === "primary" &&
      css`
        background: var(--unumBlueDark);
      `}
    ${({ variant }) =>
      variant === "secondary" &&
      css`
        background: #0a6036;
      `}
  }
`;
