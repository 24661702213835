/* c8 ignore start */  //Loading circles cannot be tested
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import UnumLoadingCircles from "../../assets/icons/UnumLoadingOptimised.gif";
import { useAppSelector } from "../../hooks/reduxhooks";

type loadingProps = {
  isLoading?: boolean;
  withoutDialogue?: boolean;
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    maxWidth: "400px !important",
    borderRadius: "10px",
  },
}));

const LoadingCircles: FC<loadingProps> = ({
  isLoading = false,
  withoutDialogue = false,
}) => {
  const message = useAppSelector(
    (state) => state.ClaimSlice.loadingData.message
  );

  const circles = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "200px",
        alignSelf: "center",
      }}
    >
      <img
        alt="Loading Circles"
        data-testid="loading-circles"
        style={{ width: withoutDialogue ? "60px" : "120px" }}
        src={UnumLoadingCircles}
      ></img>
      {!withoutDialogue && (
        <label
          style={{
            marginTop: "20px",
            justifyContent: "center",
            color: "#5a7184",
            whiteSpace: "nowrap",
          }}
        >
          {` ${message} ...`}
        </label>
      )}
    </div>
  );

  if (withoutDialogue) {
    return circles;
  }

  return <StyledDialog open={isLoading}>{circles}</StyledDialog>;
};

export default LoadingCircles;
/* c8 ignore end */
