import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialHelpPanelSlice: { openDrawer: boolean; selectedBox: string } = {
  openDrawer: false,
  selectedBox: "",
};

const HelpPanelSlice = createSlice({
  name: "helpPanel",
  initialState: initialHelpPanelSlice,
  reducers: {
    setOpenDrawer: (state, action: PayloadAction<boolean>) => {
      state.openDrawer = action.payload;
    },
    setSelectedBox: (state, action: PayloadAction<string>) => {
      state.selectedBox = action.payload;
    },
  },
});

export const { setOpenDrawer, setSelectedBox } = HelpPanelSlice.actions;

export default HelpPanelSlice.reducer;
