import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { userRolesType } from "../../types/enums/User/Roles";

type user = {
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  role: userRolesType;
  jobTitle: string;
  PhoneNumber: string;
  hasCashplanAccess: boolean;
  isAuthenticated: boolean;
};

const initialUserState: user = {
  firstName: "",
  lastName: "",
  email: "",
  userId: "",
  role: undefined,
  jobTitle: "",
  PhoneNumber: "",
  hasCashplanAccess: false,
  isAuthenticated: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.PhoneNumber = action.payload;
    },
    setJobTitle: (state, action: PayloadAction<string>) => {
      state.jobTitle = action.payload;
    },
    setCashplanAccess: (state, action: PayloadAction<boolean>) => {
      state.hasCashplanAccess = action.payload;
    },
    setUser: (state, action: PayloadAction<user>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.userId = action.payload.userId;
      state.role = action.payload.role;
      state.PhoneNumber = action.payload.PhoneNumber;
      state.jobTitle = action.payload.jobTitle;
      state.hasCashplanAccess = action.payload.hasCashplanAccess;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    clearUser: (state) => {
      state.firstName = "";
      state.lastName = "";
      state.email = "";
      state.userId = "";
      state.role = undefined;
      state.PhoneNumber = "";
      state.jobTitle = "";
      state.hasCashplanAccess = false;
      state.isAuthenticated = false;
    },
  },
});

export const {
  setFirstName,
  setLastName,
  setEmail,
  setUserId,
  setUser,
  setJobTitle,
  setPhoneNumber,
  setCashplanAccess,
  clearUser,
  setIsAuthenticated,
} = userSlice.actions;

export const userActions = userSlice.actions;

export default userSlice.reducer;
