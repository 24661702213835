export const products = {
  GIP: { label: "Group Income Protection", abrv: "GIP", backend: "GIP" },
  REHAB: {
    label: "Group income protection Absence management support",
    inprog_abrv: "Absence management",
    completed_abrv: "Absence management",
    backend: "GIPVRS",
  },
  GL: { label: "Group Life", abrv: "GL", backend: "GLI" },
  CIC: { label: "Group Critical Illness", abrv: "GCI", backend: "GCI" },
};

export const prodsArray = [
  products.GIP.backend,
  products.REHAB.backend,
  products.GL.backend,
  products.CIC.backend,
];
export type productType = keyof typeof products;
