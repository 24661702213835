import style from "./TopMenu.module.scss";
import HelpButton from "../HelpButton/HelpButton";
import { FC } from "react";
type topMenuProps = {
  title: string;
  showHelp: boolean;
};

const TopMenu: FC<topMenuProps> = ({ title, showHelp }) => {
  return (
    <div className={style.TM_Main}>
      <div className={style.TM_Left}>
        <h1 data-autoid="H1_Layout_Title" className={style.TM_Title + " h3"}>
          {title}
        </h1>
      </div>
      <div className={style.TM_Right}>
        <div className={style.TM_HelpWrapper} data-testid="Help_Button">{showHelp && <HelpButton />}</div>
      </div>
    </div>
  );
};

export default TopMenu;
