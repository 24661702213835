import { useAppSelector, useAppDispatch } from "./reduxhooks";
import { setOpenDrawer, setSelectedBox } from "../redux/slices/HelpPanelSlice";

const useHelpPannel = () => {
  const { openDrawer, selectedBox } = useAppSelector(
    (state) => state.HelpPanelSlice
  );
  const dispatch = useAppDispatch();

  const closeDrawer = () => {
    dispatch(setOpenDrawer(false));
  };

  const handleDrawerChange = (name: string) => {
    dispatch(setOpenDrawer(true));
    dispatch(setSelectedBox(name));
  };

  return { openDrawer, selectedBox, closeDrawer, handleDrawerChange };
};

export default useHelpPannel;
