import "./Homepage.scss";
import { useNavigate } from "react-router-dom";
import APP_URL_CONSTS from "../../navigation/AppUrls";
import { BentoBox } from "../../components/Storybook/BentoBox/BentoBox";
import { SimpleText } from "../../components/Storybook/SimpleText/SimpleText";
import Greetings from "../../components/Storybook/Greeting/Greetings";
import { PromotionalBanner } from "../../components/Storybook/PromotionalBanner/PromotionalBanner";
import pic from "../../assets/icons/HomePage_RehabBanner.png";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useAppSelector, useAppDispatch } from "./../../hooks/reduxhooks";

import useGetUser from "../../hooks/useGetUser";

const Homepage = () => {
  const navigate = useNavigate();

  const { hasCashPlanAccess, hasEmployerAccess, user } = useGetUser();


  const bentoBoxVariant = hasCashPlanAccess && hasEmployerAccess ? "4" : "3";
  const componentsArray = [];

  //Logic must be added later to change states when Kontent.ai is loaded
  const isCmsLoading = useAppSelector(
    (state) => state.CmsLoadingSlice.isCmsLoading
  );
  //Redirect user if disabled
  if (process.env.REACT_APP_USE_HOMEPAGE === "false") {
    navigate("/claims");
  }
  // Greetings component
  componentsArray.push(
    isCmsLoading ? (
      <Stack spacing={1} sx={{ height: "100%", width: "100%" }}>
        <Skeleton
          variant="text"
          sx={{ fontSize: "25px", height: "100%", width: "100%" }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "100px", height: "100%", width: "100%" }}
        />
      </Stack>
    ) : (
      <Greetings
        body="Here’s what's new. We're thrilled to unveil a suite of new features designed to elevate your experience. Dive into our intuitive interface and discover innovative ways to connect, create, and collaborate."
        initialGreeting="Welcome"
        name={user.firstName + " " + user.lastName}
      />
    )
  );

  // PromotionalBanner component
  componentsArray.push(
    isCmsLoading ? (
      <Skeleton variant="rectangular" width="100%" height="100%" />
    ) : (
      <PromotionalBanner
        header="Rehabilitation and return to work support"
        headerAltText="Promotional image of a woman smiling with a cup of coffee. This will be replaced with Kontent.ai"
        image={pic}
        subHeader="Rehabilitation and return to work support"
      />
    )
  );

  // SimpleText component for GroupClaimsAccess
  if (hasEmployerAccess) {
    componentsArray.push(
      isCmsLoading ? (
        <Skeleton variant="rounded" width={700} height={250} />
      ) : (
        <SimpleText
          RedirectCallBack={() => {
            navigate(`${APP_URL_CONSTS.CLAIMS}`);
          }}
          body="Claims & Absence management"
          initialGreetings="Life, Income protection and Critical illness"
          variant="primary"
        />
      )
    );
  }

  // SimpleText component for CashplanAccess
  if (hasCashPlanAccess) {
    componentsArray.push(
      isCmsLoading ? (
        <Skeleton variant="rounded" width={700} height={250} />
      ) : (
        <SimpleText
          RedirectCallBack={() => {
            if (process.env.REACT_APP_CASHPLAN_REDIRECT_URL == null) {
              navigate(`${APP_URL_CONSTS.ERRORPAGE}`);
            } else {
              window.open(
                process.env.REACT_APP_CASHPLAN_REDIRECT_URL,
                "_blank"
              );
            }
          }}
          body="Access your health plan"
          initialGreetings="Health Plan 360"
          variant="secondary"
        />
      )
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        padding: "40px",
      }}
    >
      <div>
        <BentoBox variant={bentoBoxVariant}>{componentsArray}</BentoBox>
      </div>
    </div>
  );
};

export default Homepage;
