import styled from "styled-components";

export const StyledBlock = styled.div`
  display: flex;
  padding: var(--Spacing-4, 16px) var(--Spacing-4, 16px) var(--Spacing-4, 16px)
    var(--Spacing-6, 24px);
  align-items: center;
  align-self: stretch;
`;

export const StyledBannerImage = styled.div<{ type?: "Primary" | "Secondary" }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  height: 100%;
  width: 100%;
  flex: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; // or 'contain' depending on your needs
  }
`;

export const StyledLowerBanner = styled.div<{ type?: "Primary" | "Secondary" }>`
  display: flex;
  padding: var(--Spacing-4, 16px) var(--Spacing-4, 16px) var(--Spacing-4, 16px)
    var(--Spacing-6, 24px);
  align-items: center;
  align-self: stretch;
  width: 100%;
  background-color: ${(props) =>
    props.type === "Secondary" ? "transparent" : "var(--unumBlue)"};
`;
export const StyledLowerBannerInside = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

export const StyledHeader = styled.div`
  color: var(--Color-Text-color-text-inverse, #fff);
  /* H5/Desktop/Medium */
  font-family: Ubuntu;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
`;

export const StyledSubHeader = styled.div`
  color: var(--Color-Text-color-text-inverse, #fff);
  /* Body 4/Desktop/Medium */
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;
export const StyledIcon = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 48px;
  height: 48px;
  cursor: pointer;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: var(--Spacing-6, 24px);
  overflow: hidden;
  position: relative; // Add this line
`;
