import { Navigate, Outlet, useLocation } from "react-router-dom";
import { FC, useState, useEffect, useCallback } from "react";
import { TokenManager } from "@forgerock/javascript-sdk";
import { useAppDispatch, useAppSelector } from "../hooks/reduxhooks";
import { getRoleFromAttributes, roleCheck } from "../utils/User/Roles";
import { userRolesType } from "../types/enums/User/Roles";
import { setIsAuthenticated } from "../redux/slices/userSlice";
import APP_URL_CONSTS from "../navigation/AppUrls";
import useUpdateUserInfomation from "../hooks/User/useUpdateUserInfomation";
import LoadingCircles from "../components/LoadingCircles/LoadingCircles";

type privateRouteProps = {
  expectedRoles?: userRolesType[];
};

const PrivateRoute: FC<privateRouteProps> = ({ expectedRoles }) => {
  const isAuth = useAppSelector((state) => state.userSlice.isAuthenticated);
  const [authStatus, setAuthStatus] = useState<
    "checking" | "valid" | "invalid" | "invalidRoles"
  >("checking");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { updateUser } = useUpdateUserInfomation();
  const [overWaitingThreshold, setOverWaitingThreshold] = useState(false);

  //We dont want to display the popup if the user will be authed within a 0.5 seconds to avoid flikering
  const waitToDisplayLoading = () => {
    const timeoutId = setTimeout(() => {
      setOverWaitingThreshold(true);
    }, 500);
    return timeoutId;
  };

  const getUserStatus = (
    expectedRoles: userRolesType[] | undefined,
    userRoles: userRolesType[]
  ) => {
    if (!expectedRoles || roleCheck(expectedRoles, userRoles)) {
      return "valid";
    } else {
      return "invalidRoles";
    }
  };

  const checkUserSession = useCallback(async () => {
    try {
      setAuthStatus("checking");
      const user: any = await updateUser();
      const status = getUserStatus(expectedRoles, [
        getRoleFromAttributes(user?.["non-unique-attribute"]),
      ]);
      setAuthStatus(status);
    } catch (error) {
      const tokens = await TokenManager.getTokens({
        login: "redirect",
      });
      if (tokens) {
        const user: any = await updateUser();
        const status = getUserStatus(expectedRoles, [
          getRoleFromAttributes(user?.["non-unique-attribute"]),
        ]);
        setAuthStatus(status);
        return;
      }
      setAuthStatus("invalid");
      dispatch(setIsAuthenticated(false));
    }
  }, [location.pathname]);

  useEffect(() => {
    setOverWaitingThreshold(false);
    const timeId = waitToDisplayLoading();
    checkUserSession();
    return () => {
      //This will clear all times if the component dismounts or completes
      clearInterval(timeId);
    };
  }, [checkUserSession]);

  switch (authStatus) {
    case "checking":
      return (
        <>
          {isAuth && <Outlet />}
          {overWaitingThreshold && <LoadingCircles isLoading={true} />}
        </>
      );
    case "valid":
      return <Outlet />;
    case "invalidRoles":
      return <Navigate to={APP_URL_CONSTS.ERRORPAGE} />;
    default:
      return null;
  }
};

export default PrivateRoute;
