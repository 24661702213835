import style from "./HelpButton.module.scss";
import HelpIcon from "../../../assets/icons/HelpIcon.svg";
import useHelpPannel from "../../../hooks/UseHelpPanel";

const HelpButton = () => {
  const { handleDrawerChange } = useHelpPannel();
  return (
    <div style={{ position: "relative" }}>
      <button
        data-autoid="BTN_Get_Help"
        className={style.HB_Button}
        onClick={() => {
          handleDrawerChange("");
        }}
      >
        {<img src={HelpIcon} alt="help icon" />}
        Get help
      </button>
    </div>
  );
};

export default HelpButton;
