import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";
import reducers from "../slices";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
const persistConfig = {
  key: "root",
  version: 1,
  storage: storageSession,
  whitelist: ["ClaimSlice", "userSlice"], // added so that only the claimSlice is kept in session storage
};

const combinedReducer = combineReducers({
  ClaimSlice: reducers.ClaimSlice,
  HelpPanelSlice: reducers.HelpPanelSlice,
  userSlice: reducers.UserSlice,
  BreadcrumbSlice: reducers.BreadcrumbSlice,
  CmsLoadingSlice: reducers.CmsLoading,
});

const persistedReducer = persistReducer(persistConfig, combinedReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
