import styled from "styled-components";

export const StyledGreetingBody = styled.div`
  color: #2e2e2e;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const StyledHeadingContainer = styled.div`
  color: var(--unumBlue);
  font-family: Ubuntu;
  font-size: 3.25rem;
  font-style: normal;
  line-height: 60px;

  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  margin-bottom: 1rem;
`;

export const StyledGreeting = styled.div`
  font-weight: 400;
`;

export const StyledGreetingBold = styled.div`
  font-weight: 500;
`;
