import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialCmsLoadingSlice: { isCmsLoading: boolean } = {
  isCmsLoading: false,
};
const cmsLoadingSlice = createSlice({
  name: "isCmsLoading",
  initialState: initialCmsLoadingSlice,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isCmsLoading = action.payload;
    },
  },
});

export const { setLoading } = cmsLoadingSlice.actions;
export default cmsLoadingSlice.reducer;
