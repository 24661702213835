import { TokenManager } from "@forgerock/javascript-sdk";
import LoginSpinner from "../../components/LoginSpinner/LoginSpinner";
import { useEffect } from "react";

const Logout = () => {
  useEffect(() => {
    const ForceLogin = async () => {
      //ForceRenew will make the user relog
      await TokenManager.getTokens({ login: "redirect", forceRenew: true });
    };
    ForceLogin();
  }, []);

  return <LoginSpinner />;
};

export default Logout;
