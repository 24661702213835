import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Config } from "@forgerock/javascript-sdk";

Config.set({
  clientId: process.env.REACT_APP_WEB_OAUTH_CLIENT,
  redirectUri: process.env.REACT_APP_FR_REDIRECT_URI,
  scope: process.env.REACT_APP_SCOPE,
  serverConfig: {
    baseUrl: process.env.REACT_APP_AM_URL ? process.env.REACT_APP_AM_URL : "",
    timeout: 10000,
  },
  realmPath: process.env.REACT_APP_REALM_PATH,
  oauthThreshold: 60000,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const persistor = persistStore(store);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
